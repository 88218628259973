<template>
  <div id="myCoupon" class="fixedMode">
    <div class="fixedTop">
      <span class="topTab" :class="{ cur: curType == 0 }"
        >{{ typeList[0] }}(2)</span
      >
      <span class="topTab" :class="{ cur: curType == 1 }"
        >{{ typeList[1] }}(1)</span
      >
      <span class="topTab" :class="{ cur: curType == 3 }"
        >{{ typeList[2] }}(0)</span
      >
    </div>
    <div class="mainScroll">
      <div class="couponList">
        <div class="aCoupon">
          <div class="couponLeft">
            <div class="couponNum">100</div>
            <div class="couponCondition">满100使用</div>
          </div>
          <div class="couponRight">
            <div class="couponInfo">
              <p class="name">100元商城抵扣券</p>
              <p class="time">有效期：2020.12.12-2020.12.31</p>
            </div>
            <p class="couponTips">全场通用</p>
          </div>
        </div>
        <div class="aCoupon">
          <div class="couponLeft">
            <div class="couponNum">50</div>
          </div>
          <div class="couponRight">
            <div class="couponInfo">
              <p class="name">50元商城提货券</p>
              <p class="time">有效期：2020.12.12-2020.12.31</p>
            </div>
            <p class="couponTips">全场通用</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myCoupon",
  data() {
    return {
      curType: 0, //  当前优惠券类型
      typeList: {
        //  优惠券类型列表 对应文字
        0: "未使用",
        1: "已使用",
        2: "已过期",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.fixedTop {
  background: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 20px solid #efefef;
  padding: 0 56px;
  .topTab {
    min-width: 160px;
    text-align: center;
    font-size: 30px;
    line-height: 100px;
    height: 100px;
    position: relative;
    color: #999;
    display: block;
    &.cur::after {
      content: "";
      display: block;
      width: 54px;
      height: 8px;
      border-radius: 8px;
      background: linear-gradient(to right, #f23441, #f86340);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.mainScroll {
  background: #efefef;
}
.couponList {
  margin: 0 30px;
  padding: 20px 0;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  .aCoupon {
    display: flex;
    margin-top: 20px;
    .couponLeft {
      border-radius: 10px 0 0 10px;
      width: 216px;
      height: 190px;
      background: linear-gradient(135deg, #f84f36, #f99d5f);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .couponNum {
        color: #fff;
        font-size: 72px;
        line-height: 1;
        font-weight: bold;
        display: inline-block;
        &::before {
          content: "￥";
          font-size: 30px;
          vertical-align: top;
          font-weight: normal;
          letter-spacing: -5px;
        }
      }
      .couponCondition {
        padding-top: 10px;
        font-size: 24px;
        color: #ffdec9;
        line-height: 1;
      }
    }
    .couponRight {
      flex: 1;
      height: 190px;
      background: #fff;
      border-radius: 0 10px 10px 0;
      position: relative;
      padding: 0 25px 0 30px;
      &::before,
      &::after {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        border-radius: 50%;
        background: #efefef;
        left: -10px;
      }
      &::before {
        top: -11px;
      }
      &::after {
        bottom: -11px;
      }
      .couponInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 120px;
        border-bottom: 1px dashed #dadada;
        .name {
          line-height: 1.2;
          font-size: 30px;
        }
        .time {
          line-height: 1.2;
          font-size: 24px;
          color: #999;
          padding-top: 10px;
        }
      }
      .couponTips {
        color: #999;
        font-size: 24px;
        padding-top: 15px;
        line-height: 1;
      }
    }
    &.guoqi,
    &.yishiyong {
      .couponLeft {
        background: #b2b2b2;
        .couponCondition {
          color: #fff;
        }
      }
      .couponRight {
        color: #999;
      }
    }
    &.guoqi {
      .couponRight {
        background: #fff url(~@/assets/member/sign-guoqi.png) right bottom / 120px
          auto no-repeat;
      }
    }
    &.yishiyong {
      .couponRight {
        background: #fff url(~@/assets/member/sign-yishiyong.png) right bottom /
          120px auto no-repeat;
      }
    }
  }
}
</style>